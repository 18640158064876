import React from 'react';
import Layout from '../../components/layout';
import Questions from '../../components/Questions';

const BehandlungMimischerFaeltchenMitBotox = ({ data, location, ...props }) => {
    return (
        <Layout
            location={location}
            title="Behandlung mimischer Fältchen mit Botox"
            noSlider
            {...props}
        >
            <p>
                Unsere mehr oder weniger stark ausgeprägte Gesichtsmimik zeigt
                gelegentlich auch schon in jüngeren Jahren Spuren davon.
            </p>
            <p>Manche Gesichter wirken davon vielleicht interessanter.</p>
            <p>
                Manchmal vermitteln diese Spuren den Eindruck von Erschöpfung,
                Müdigkeit, oder auch Alter.
            </p>
            <p>Nicht jeder möchte dies vermitteln.</p>
            <p>
                Die Behandlung mit Botulinum ist die mit Abstand am häufigsten
                verwendete Substanz in der ästhetischen Medizin zur Verminderung
                der mimischen Fältchen.
            </p>
            <p>
                Das Gesicht wirkt glatter, entspannter, frischer, und auch
                jünger.
            </p>
            <p>
                Wir behandeln immer so, dass Ihr Gesicht nicht leblos wirkt, es
                aber auch nicht grenzenlos zeigt, wenn wir schlecht geschlafen
                haben, Stress haben, einfach nicht so gut drauf sind.
            </p>
            <p>
                Abschreckende Fotos aus den Printmedien kennt sicher fast Jeder.
            </p>
            <p>
                Unser Gesicht sollte im entspannten Zustand auch ebenso wirken.
                Wenn wir jedoch emotional Mimik zeigen wollen, ja müssen, dann
                sollte da auch noch etwas an Mimik kommen.
            </p>
            <p>
                Diese feine Einstellung ist das Geheimnis gelungener
                Botulinum-Therapie.{' '}
            </p>

            <Questions title="Behandlung mimischer Fältchen mit Botox" />
        </Layout>
    );
};

export default BehandlungMimischerFaeltchenMitBotox;
